// import AOS from 'aos';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import Swiper, { Navigation, Pagination, Autoplay, EffectFade , Thumbs } from 'swiper';
import 'public/js/svgxuse.min';
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import scrollSpy from 'simple-scrollspy';
import 'dropify/dist/js/dropify';


Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Thumbs]);

window.Swiper = Swiper;
// JavaScript
$(document).ready( function () {
  //scroll top
  $('.back-to-top').click(function(event){
    $("html,body").animate({scrollTop: 0}, 1000);
  });
  // mobile header back btn
  var back_page = document.referrer;
  var this_origin = window.location.origin;
  var filter_path = $('.frame').data('filter-path'); // 網址塞選條件
  var back_url = $('.frame').data('back-url'); // 自訂上一頁連結（看上一頁，決定是否進入自訂連結）
  var back_assign = $('.frame').data('back-assign'); // if true 不管如何點擊返回都會前往指定連結
  $("#header_back").on("click", function() {
    if (back_page.includes(this_origin)) {
      if (back_url && (back_assign || filter_path && back_page.includes(filter_path) || window.location.href.includes('#'))) {
        window.location.href = back_url;
      } else {
        history.back();
      }
    } else if (back_url) {
      window.location.href = back_url;
    } else {
      window.location.href = this_origin;
    }
  });

  var indexBanner = new Swiper('#indexBanner', {
    effect: 'fade',
    autoplay: {
      delay: 5000,
    },
    loop: true,
    peed: 1000,
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
  });

  var indexProducts = new Swiper('#indexProducts', {
    slidesPerView: 1.5,
    spaceBetween: 17,
    slidesOffsetBefore: 20,
    slidesOffsetAfter: 50,
    peed: 1000,
    breakpoints: {
      640: {
        slidesPerView: 2.5,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 50,
      },
      800: {
        slidesPerView: 3,
        spaceBetween: 34,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
      },
    },
    navigation: {
      nextEl: '.swiper-btn-next',
      prevEl: '.swiper-btn-prev',
    },
  });

  var indexStory = new Swiper('#indexStory', {
    slidesPerView: 3.5,
    spaceBetween: 20,
    peed: 1000,
    breakpoints: {
      0: {
        slidesPerView: 1.3,
        spaceBetween: 18,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 50,
      },
      640: {
        slidesPerView: 2.3,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 50,
      },
      800: {
        centeredSlides: true,
        initialSlide: 2,
      },
      1600: {
        slidesPerView: 4,
        centeredSlides: true,
        initialSlide: 2,
      },
      2001: {
        slidesPerView: 3,
        centeredSlides: true,
        initialSlide: 2,
      },
    },
    navigation: {
      nextEl: '.swiper-btn-next',
      prevEl: '.swiper-btn-prev',
    },
  });

  // table responsive
  if ($('.custom-editor table').length >= 1) {
    $('.custom-editor table').wrap('<div class="table-responsive"/></div>');
  }

  // select 空值的時候樣式不同
  $('select.form-control').on('change', function () {
    let value = $(this).val();
    if (value == '') {
      $(this).addClass('val-empty');
    } else {
      $(this).removeClass('val-empty');
    }
  });

  // tab
  $('.tab[data-tab]').on('click', function () {
    var tabPanes = $(this).data('tabs');
    var ID = $(this).data('tab');
    $(this).addClass('is-active').siblings().removeClass('is-active');
    $("[data-tab-panes=" + tabPanes + "]").find("[data-tab-pane=" + ID + "]").addClass('is-active').siblings().removeClass('is-active');
  });

  // float menu
  $('.float-menu-btn').on('click', function () {
    var id = $(this).data('mobile-menu-btn');
    $('[data-mobile-menu-content=' + id + ']').addClass('is-active');
    $("body").addClass("overflow-h");
  });

  $('.fmc-close').on('click', function () {
    $(this).closest('[data-mobile-menu-content]').addClass('is-fade');
    setTimeout(() => {
      $(this).closest('[data-mobile-menu-content]').removeClass('is-fade is-active');
    }, "300");
    $("body").removeClass("overflow-h");
  });

  // frame [data-mobile-menu="hide"]
  // 手機版下方選單藏起來
  var hideMobileMenu = $('.frame').data('mobile-menu');
  if (hideMobileMenu) {
    $('#floatMenu').addClass('is-hide');
  } else {
    $('#floatMenu').removeClass('is-hide');
  }

  // 浮動側邊欄
  let scrollspyMenu = document.getElementById("scrollspyMenu");
  if (scrollspyMenu) {
    scrollSpy('#scrollspyMenu', options);
    var windowWidth = $(window).width();
    let observer = new MutationObserver(
      (mutationRecords) => menuScroll()
    );

    if (windowWidth <= 800) {
      observer.observe(scrollspyMenu, {
        attributes: true,
        subtree: true,
      });
    }
  }

  const options = {
    sectionClass: '.scrollspy',
    menuActiveTarget: '.scrollspy-link',
    offset: 0,
  }

  function menuScroll() {
    setTimeout(() => {
      let item = $('.scrollspy-link.active').outerWidth();
      let item_left_size = $('.scrollspy-link.active').offset().left;
      let scrollTo = item_left_size - (windowWidth / 2 - item / 2);
      scrollspyMenu.scrollLeft += scrollTo;
    }, 100);
  }

  $(window).on('scroll', function () {
    // transparent header
    if ($(window).scrollTop() > 100) {
      $('.header').addClass("is-min");
      $(".back-to-top").fadeIn();
    } else {
      $('.header').removeClass("is-min");
      $(".back-to-top").fadeOut();
    }
  });

  // 商品詳細介紹，圖片zoom in
  var lightbox = new PhotoSwipeLightbox({
    gallery: '#products-gallery',
    children: 'a',
    pswpModule: () => import('photoswipe'),
    padding: { top: 30, bottom: 30, left: 10, right: 10 },
    maxWidthToAnimate: 1500,
  });

  lightbox.on('uiRegister', function () {
    lightbox.pswp.ui.registerElement({
      name: 'custom-caption',
      order: 100,
      isButton: false,
      appendTo: 'root',
      html: '',
      onInit: (el, pswp) => {
        lightbox.pswp.on('change', () => {
          const currSlideElement = lightbox.pswp.currSlide.data.element;
          let captionHTML = '';
          if (currSlideElement) {
            const hiddenCaption = currSlideElement.querySelector('.hidden-caption-content');
            if (hiddenCaption) {
              // get caption from element with class hidden-caption-content
              captionHTML = hiddenCaption.innerHTML;
            } else {
              // get caption from alt attribute
              captionHTML = currSlideElement.querySelector('img').getAttribute('alt');
            }
          }
          el.innerHTML = captionHTML || '';
        });
      }
    });
  });
  lightbox.init();

  // 客製化，圖片zoom in
  var custom_lightbox = new PhotoSwipeLightbox({
    gallery: '#custom_lightbox',
    children: 'a',
    pswpModule: () => import('photoswipe'),
    padding: { top: 30, bottom: 30, left: 10, right: 10 },
    maxWidthToAnimate: 1500,
    arrowPrev: false,
    arrowNext: false,
    counter: false
  });
  custom_lightbox.init();
});
