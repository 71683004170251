import 'sweetalert2/dist/sweetalert2.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';

const Swal_lg = Swal.mixin({
  width: 880,
  showCloseButton: true,
  showConfirmButton: false,
  customClass: {
    container: 'mobile-full',
  },
});

const Swal_hint = Swal.mixin({
  width: 300,
  showCloseButton: true,
  showConfirmButton: false,
  backdrop: 'transparent',
  customClass: {
    popup: 'popup-hint',
    htmlContainer: 'html-hint',
  },
});

const SwalConfirm = Swal.mixin({
  width: 300,
  backdrop: 'transparent',
  showConfirmButton: true,
  showCancelButton: true,
  icon: 'question',
  customClass: {
    popup: 'popup-hint popup-order',
    title: 'swal-hint-text',
    actions: 'btn-group',
    confirmButton: 'btn-primary',
    cancelButton: 'btn-outerline btn-min color-primary cancel',
    icon: 'swal-icon-custom confirm',
    htmlContainer: 'html-hint',
  },
  buttonsStyling: false,
  reverseButtons: true,
});

const SwalNotify = Swal.mixin({
  width: 300,
  backdrop: 'transparent',
  showCloseButton: true,
  showConfirmButton: false,
  customClass: {
    popup: 'popup-hint popup-order',
    title: 'swal-hint-text',
  },
});

const SwalAlert = Swal.mixin({
  width: 300,
  backdrop: 'transparent',
  showCloseButton: true,
  showConfirmButton: false,
  icon: 'warning',
  customClass: {
    popup: 'popup-hint popup-order',
    title: 'swal-hint-text',
    icon: 'swal-icon-custom alert',
  },
});

const SwalError = Swal.mixin({
  width: 300,
  backdrop: 'transparent',
  showCloseButton: true,
  showConfirmButton: false,
  icon: 'error',
  customClass: {
    popup: 'popup-hint popup-order',
    title: 'swal-hint-text',
    icon: 'swal-icon-custom error',
  },
});

window.Swal = Swal;
window.Swal_lg = Swal_lg;
window.Swal_hint = Swal_hint;
window.SwalConfirm = SwalConfirm;
window.SwalNotify = SwalNotify;
window.SwalAlert = SwalAlert;
window.SwalError = SwalError;
