import 'public/js/sweetalert';
import 'public/js/tw-city-selector';
import SyncSelect from 'public/js/sync-select'
import Cookies from 'js-cookie';
import 'core-js';
import 'public/main'
import 'public/style.scss'
import '@nathanvda/cocoon'
import 'select2';

import Rails from "@rails/ujs"
Rails.start();

window.jQuery = $;
window.$ = $;
window.Cookies = Cookies;
window.SyncSelect = SyncSelect;

